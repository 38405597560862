import React from "react"
import style from './index.module.less'

import { contactBg } from '@/images/index'
import { emailBlackLogo, discordIcon ,redditLogo} from '@/images/support/index'
import { DefaultLink, Layout, SEO } from '@/components/index'
import {
  youtube,
  twitter,
  ins,
  reddit
} from '@/images/air/index'
import { useIntl } from "react-intl"
import getCountry from '@/utils/getCountry.js'
import { useInView } from "react-intersection-observer"

const Index = () => {
  const intl = useIntl()
  const region = getCountry()
  const [headerRef, headerRefInView] = useInView({ threshold: 0 });
  const [bodyRef, bodyRefInView] = useInView({ threshold: 0 });
  const contactList = [
    {
      icon: region=='jp'?discordIcon:redditLogo,
      title:region=='jp'?intl.formatMessage({ id: "footerList.contact", defaultMessage: "Community Forum" }): intl.formatMessage({ id: "support_join_title", defaultMessage: "Join Us" }),
      text:region=='jp'? intl.formatMessage({ id: "support_contact_ask", defaultMessage: "Ask questions or search immediately" }): intl.formatMessage({ id: "support_join_desc", defaultMessage: "Join the XREAL community, connect with users!" }),
      link:region=='jp'? process.env.url_discord_jp: process.env.url_reddit
    },
    {
      icon: emailBlackLogo,
      title: intl.formatMessage({ id: "support_contact_email", defaultMessage: "Contact by Email" }),
      text:intl.formatMessage({ id: "contact_email"})
    }
  ]

  return (
    <Layout footerBackground='#F2F2F2' footerTextWhite={false} menuBlack={!headerRefInView && bodyRefInView ? true : false}>
      <SEO page="Contact_Us" />
      <div className={style.header} ref={headerRef} style={{ backgroundImage: `url(${contactBg})` }}>
        {intl.formatMessage({ id: "footerList.contact", defaultMessage: "Contact Us" })}
      </div>
      <div className={style.body} ref={bodyRef}>
        <div className={style.method}>
          {contactList.map((item, index) => (
            <div className={style.methodItem} key={`contactUs-method${index}`}>
              <img src={item.icon} />
              <div className={style.methodItemTitle}>{item.title}</div>
              <div className={style.methodItemText}>
                {index == 0 ? <a href={item.link} target='_blank'>{item.text}</a> :<div   dangerouslySetInnerHTML={{ __html:item.text }} ></div> }
              </div>
            </div>
          ))}
        </div>
        <div className={style.media}>
          <div className={style.mediaTitle}>{intl.formatMessage({ id: "support_touch", defaultMessage: "You can also get in touch with us in the following ways" })}</div>
          <div className={style.mediaBox}>
            <img src={youtube} onClick={() => window.open(region == 'jp' ? process.env.url_youtube_jp : process.env.url_youtube)} />
            <img src={twitter} onClick={() => window.open(region == 'jp' ? process.env.url_twitter_jp : process.env.url_twitter)} />
            <img src={ins} onClick={() => window.open(region == 'jp' ? process.env.url_ins_jp : process.env.url_ins)} />
            <img src={reddit} onClick={()=>window.open(process.env.url_reddit)} />

          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Index
